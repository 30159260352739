import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { FC } from "react";

// Define the type for your form values
type FormValues = {
  [key: string]: any; // This could be more specific, like string, number, etc.
};

type Props = {
  name: string;
  label: string;
  required?: boolean;
  type?: string;
};

const InputField: FC<Props> = ({ name, label, required, type = "text" }) => {
  // Use the context with a specific type for Formik values
  const { touched, errors }: { touched: FormValues; errors: FormValues } =
    useFormikContext<FormValues>();

  return (
    <div className="fv-row mb-0">
      <label className="fs-6 form-label required">{label}</label>
      <Field
        type={type}
        name={name}
        className="form-control form-control-lg form-control-solid"
      />
      {/* Show error only if the field is touched and has an error */}

      <div className="text-danger mt-2">
        {touched[name] && errors[name] && <ErrorMessage name={name} />}
      </div>
    </div>
  );
};

export default InputField;
