import React from "react";

const SearchBox = () => {
  return (
    <div className="form-group has-search d-flex align-items-center ">
      <form
        data-kt-search-element="form"
        className="w-100 position-relative "
        autoComplete="off"
      >
        <input type="hidden" />
        <i
          style={{ borderRight: "1px solid #A79B9B", paddingRight: "10px" }}
          className="ki-duotone ki-magnifier fs-2 search-icon position-absolute top-50 translate-middle-y  ms-4 "
        >
          <span className="path1" />
          <span className="path2" />
        </i>{" "}
        <input
          type="text"
          className="search-input bg-transparent form-control ps-16 fs-7 h-35px "
          name="search"
          defaultValue=""
          placeholder="Search"
          data-kt-search-element="input"
        />
      </form>
    </div>
  );
};

export default SearchBox;
