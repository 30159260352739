import { WithChildren } from "helpers";
import React, { FC } from "react";

interface Props {
  onClickEvent: () => void;
  className?: string;
}

const EventDivBoxLayout: FC<Props & WithChildren> = ({
  onClickEvent,
  className,
  children,
}) => {
  return (
    <div
      className={className}
      onClick={onClickEvent}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};

export default EventDivBoxLayout;
