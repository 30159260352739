import clsx from "clsx";
import { ThemeModeSwitcher } from "hooks‬/theme/theme-mode/ThemeModeSwitcher";
import profilePic from "../../assets/images/Profile.png";
import { HeaderUserMenu } from "./header-menus/HeaderUserMenu";

const itemClass = "ms-1 ms-md-4";
const userAvatarClass = "symbol-35px";

const Navbar = () => {
  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        {/* <Search /> */}
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img className="rounded-circle" src={profilePic} alt="" />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Navbar };
