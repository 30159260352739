import React, { FC } from "react";
interface Props {
  title: string;
}
const StepperStepFormTitle: FC<Props> = ({ title }) => {
  return (
    <div className="pb-10 pb-lg-15">
      <h2 className="fw-bolder">{title}</h2>

      <div className="text-gray-400 fw-bold fs-6">
        If you need more info, please check out{" "}
        <span className="text-primary fw-bolder"> Help Page</span>.
      </div>
    </div>
  );
};

export default StepperStepFormTitle;
