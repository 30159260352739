import PageContainer from "components/ui-components/PageContainer";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatHistoryCollapse from "./ChatHistoryCollapse";
import { KTIcon } from "helpers";
import { AiTwotoneClockCircle } from "react-icons/ai";
import { interviewApiEndpoint } from "constant/APIConstant";
import moment from "moment-timezone";
import PageLoader from "components/Loader/PageLoader";
import SingleStartRating from "../../../components/ui-components/SingleStartRating";
import apiInterceptor from "services/apiInterceptor";
import profileIcon from "assets/images/Profile.png";
import { privateRoutes } from "constant/route/privateRoutes";
import { TechnicalEvaluationChart } from "./SkillsRatingChart";
const Assessment = () => {
  const { interviewGuid } = useParams();
  const navigate = useNavigate();
  const [collapseSection, setCollapseSection] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [screeningInterview, setScreeningInterview] = useState<any>({});

  const fetchscreeningInterviewData = useCallback(async () => {
    try {
      await apiInterceptor
        .get(interviewApiEndpoint.screeningInterview + interviewGuid)
        .then((response: any) => {
          if (response.data.status === "ERROR") {
            window.alert(response.data.statusDesc);
            navigate(privateRoutes.interviewsCompleted);
          } else {
            setScreeningInterview(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [interviewGuid, navigate]);

  useEffect(() => {
    fetchscreeningInterviewData();
  }, [fetchscreeningInterviewData]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageContainer title="Assessment">
      <div className="row gap-4 gap-lg-0">
        <div className="col-12 col-lg-4 d-flex gap-4 flex-column">
          <div className="card p-5 d-flex flex-column gap-4">
            <div className="d-flex align-item-baseline gap-2 fs-16">
              <KTIcon className="fs-16 text-dark" iconName="office-bag" />
              <span>
                {" "}
                {screeningInterview.interview?.jobDetails.displayName}
              </span>
            </div>
            <div className="d-flex gap-5">
              <div className="fs-14">
                <KTIcon iconName="calendar" className="fs-16 text-dark" />{" "}
                {moment(screeningInterview?.interview.startDate).format(
                  "Do MMM YYYY"
                )}
              </div>

              <div className="fs-14">
                <AiTwotoneClockCircle className="fs-16 text-dark" />{" "}
                {moment(screeningInterview?.interview?.startDate).format(
                  "h:mm A"
                )}{" "}
                -{" "}
                {moment(screeningInterview?.interview?.endDate).format(
                  "h:mm A"
                )}
              </div>
            </div>
          </div>

          <div className="card p-5 d-flex flex-column gap-6">
            <div className="d-flex gap-2 fs-16 align-items-center">
              <div className="d-flex align-items-center flex-grow-1">
                {/* begin::Avatar */}
                <div className="symbol symbol-45px me-5">
                  <img src={profileIcon} alt="profile" />
                </div>
                <div className="d-flex flex-column">
                  <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                    {screeningInterview.interview.candidateDetails.firstName}{" "}
                    {screeningInterview.candidateDetails?.lastName}
                  </span>

                  <span className="text-gray-400 fs-14 fw-semibold">
                    {screeningInterview.interview?.jobDetails.displayName}
                  </span>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <SingleStartRating
                  score={screeningInterview.interview.interviewScore}
                />
                <span className="text-gray-800  fs-12">
                  {" "}
                  {screeningInterview.interview.interviewScore}/5
                </span>
              </div>
            </div>
            <div className="text-gray-700" style={{ textAlign: "justify" }}>
              {screeningInterview.interview.interviewSummary}
            </div>
          </div>

          <TechnicalEvaluationChart
            className="my-custom-class"
            skillsRating={screeningInterview.interview.skillsRating}
          />
        </div>

        <div className="col-12 col-lg-8">
          {screeningInterview.chatHistory.map((item: any, index: number) => {
            return (
              <div key={item.question} className="mb-4">
                <ChatHistoryCollapse
                  title={index + 1 + ". " + item.question}
                  score={item.score}
                  setCollapseSection={() => {
                    if (collapseSection === index) {
                      setCollapseSection(-1);
                    } else {
                      setCollapseSection(index);
                    }
                  }}
                  open={index === collapseSection}
                >
                  <span style={{ color: "#6F6F6F" }}>{item.answer}</span>

                  <div className="bg-muted p-4 bg-light rounded text-gray-700  mt-4 ">
                    <i>Summary: {item.summary}</i>
                  </div>
                </ChatHistoryCollapse>
              </div>
            );
          })}
        </div>
      </div>
    </PageContainer>
  );
};

export default Assessment;
