import React, { FC, useEffect, useRef, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputTextField from "components/form/InputTextField";
import MultiSelectField from "components/form/MultiSelectField";
import {
  getAiAgents,
  getAllInterviewers,
  scheduleInterview,
} from "services/interviewAPI";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { useCommonContext } from "hooks‬/CommonContext";
import { useNavigate } from "react-router";
import { privateRoutes } from "constant/route/privateRoutes";
import StepperStepFormTitle from "components/stepper/StepperStepFormTitle";
import { KTIcon, toAbsoluteUrl } from "helpers";
import SelectInput from "components/form/SelectInput";
import EventDivBoxLayout from "layout/EventDivBoxLayout";
import { FaCirclePlay } from "react-icons/fa6";
import { FaPauseCircle } from "react-icons/fa";

interface CandidateType {
  candidateApplicationGuid: string;
  jobDetailsGuid: string;
  candidateName: string;
}

interface PropsType {
  candidatesToSchedule: CandidateType[];
  backStep: () => void;
}

// Validation Schema using Yup
const validationSchemaForAIAgent = Yup.array().of(
  Yup.object().shape({
    startTime: Yup.date()
      .required("Start time is required")
      .typeError("Invalid date format"),
    endTime: Yup.date()
      .required("End time is required")
      .typeError("Invalid date format")
      .test(
        "is-greater",
        "End time must be after start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value && new Date(startTime) < new Date(value);
        }
      ),
  })
);

const validationSchemaForHuman = Yup.array().of(
  Yup.object().shape({
    selectedInterviewer: Yup.array()
      .min(1, "At least one interviewer is required")
      .required("Interviewer is required"),
    startTime: Yup.date()
      .required("Start time is required")
      .typeError("Invalid date format"),
    endTime: Yup.date()
      .required("End time is required")
      .typeError("Invalid date format")
      .test(
        "is-greater",
        "End time must be after start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value && new Date(startTime) < new Date(value);
        }
      ),
  })
);

const ScheduleInterviewForm: FC<PropsType> = ({
  candidatesToSchedule,
  backStep,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [interviewerList, setInterviewerList] = useState<any[]>([]);
  const [aiAgent, setAiAgent] = useState<any[]>([]);
  const [aiAgentLanguage, setAiAgentLanguage] = useState<any[]>([]);
  const { showSuccessToast, showErrorToast } = useCommonContext();
  const [currentSchema, setCurrentSchema] = useState(
    validationSchemaForAIAgent
  );
  const [audioPath, setAudioPath] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  // Initial values for Formik
  const initialValues = candidatesToSchedule.map((candidate) => ({
    candidateApplicationGuid: candidate.candidateApplicationGuid,
    jobDetailsGuid: candidate.jobDetailsGuid,
    selectedInterviewer: [],
    startTime: "",
    endTime: "",
    interviewType: "",
    aiAgentId: "",
    selectedAgentLanguage: {
      label: "",
      value: "",
    },
    candidateName: candidate.candidateName,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      stopAudio();
      const sendToData = values.map((item: any) => ({
        candidateApplicationGuid: item.candidateApplicationGuid,
        jobDetailsGuid: item.jobDetailsGuid,
        startTime: item.startTime.replace("T", " "),
        endTime: item.endTime.replace("T", " "),
        interviewType: item.interviewType,
        aiAgentId: item.aiAgentId.length > 0 ? item.aiAgentId : null,
        interviewers: item.selectedInterviewer.map((item: any) => ({
          interviewerGuid: item.guid,
        })),
      }));

      await scheduleInterview(sendToData)
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            showErrorToast(response.data.statusDesc);
          } else {
            showSuccessToast(t("messages:CREATED.SUCCESSFULLY"));
            navigate(privateRoutes.interviewsUpcoming);
          }
        })
        .catch((error: any) => {
          setSubmitting(false);
        });
      setSubmitting(false);
    },
  });

  const getAiAgentHandler = async () => {
    try {
      await getAiAgents()
        .then((res: any) => {
          // Set AI Agent data
          setAiAgent(res.data.data);

          // Ensure the response data is an array
          const data: any[] = res.data.data;

          if (Array.isArray(data)) {
            // Extract unique languages and convert Set to array
            const uniqueLanguages = Array.from(
              new Set(data.map((item: any) => item.agentLanguage))
            );

            // Map unique languages to desired format
            const formattedLanguages = uniqueLanguages.map((language: any) => ({
              value: language,
              label: language,
            }));

            setAiAgentLanguage(formattedLanguages);
          } else {
            console.error("The response data is not an array.");
          }
        })
        .catch((error: any) => {
          console.error("Error fetching AI Agent data:", error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const getAllInterviewersHandler = async () => {
    try {
      await getAllInterviewers()
        .then((res: any) => {
          setInterviewerList(
            res.data.data.map((item: any) => ({
              guid: item.guid,
              displayName: item.firstName + " " + item.lastName,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAiAgentHandler();
    getAllInterviewersHandler();
  }, []);

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  const audioRef = useRef<HTMLAudioElement>(null);
  const playAudio = (url: string) => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    } else {
      audioRef.current.src = url;
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Audio playback failed:", error));
    }
  };
  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio
      setIsPlaying(false);
    }
  };
  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div className="w-100">
      <audio
        ref={audioRef}
        onEnded={handleAudioEnd}
        style={{ display: "none" }}
      >
        {" "}
        <track kind="captions" />
      </audio>
      <StepperStepFormTitle title="Fill Job Details" />
      <div className="w-100">
        <div className="w-100">
          {formik.values.map((item: any, index: number) => (
            <div
              key={item.candidateApplicationGuid}
              className="row align-items-start justify-content-between mb-2 bg-white-dark radius-12 border border-dash p-4"
            >
              <div className="col-6 px-2">
                <InputTextField
                  label={`Candidate ${index + 1}`}
                  value={item.candidateName}
                  name={`${index}.candidateName`}
                  onChange={formik.handleChange}
                  isDisable={true}
                />
              </div>
              <div className="col-6  p-0 gap-0 m-0 d-flex justify-content-between">
                <div className="row p-0 justify-content-between w-100">
                  <div className="col-6">
                    <InputTextField
                      label={t("Start Time")}
                      labelClass="required"
                      type="datetime-local"
                      value={formik.values[index]?.startTime || ""}
                      onChange={formik.handleChange}
                      name={`${index}.startTime`}
                      error={Boolean(
                        (formik.errors[index] as FormikErrors<any>)
                          ?.startTime && formik.touched[index]?.startTime
                      )}
                      helperText={
                        formik.touched[index]?.startTime &&
                        (formik.errors[index] as FormikErrors<any>)?.startTime
                      }
                    />
                  </div>
                  <div className="col-6  ">
                    <InputTextField
                      label={t("End Time")}
                      name={`${index}.endTime`}
                      labelClass="required"
                      type="datetime-local"
                      value={formik.values[index]?.endTime || ""}
                      onChange={formik.handleChange}
                      error={Boolean(
                        (formik.errors[index] as FormikErrors<any>)?.endTime &&
                          formik.touched[index]?.endTime
                      )}
                      helperText={
                        formik.touched[index]?.endTime &&
                        (formik.errors[index] as FormikErrors<any>)?.endTime
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 px-2">
                <div className="fv-row mb-5">
                  <div className="fs-6 form-label my-2 required">
                    Interview type
                  </div>
                  <div className="d-flex gap-4">
                    <span className="d-flex gap-1">
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`${index}.interviewType`}
                          value="SCREENING"
                          checked={
                            formik.values[index]?.interviewType === "SCREENING"
                          }
                          onChange={(e) => {
                            formik.handleChange(e);
                            setCurrentSchema(validationSchemaForAIAgent);
                          }}
                        />
                      </div>
                      AI Agent
                    </span>
                    <span className="d-flex gap-1">
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`${index}.interviewType`}
                          value="HUMAN"
                          checked={
                            formik.values[index]?.interviewType === "HUMAN"
                          }
                          onChange={(e) => {
                            formik.handleChange(e);
                            setCurrentSchema(validationSchemaForHuman);
                          }}
                        />
                      </div>
                      Human interviewer
                    </span>
                  </div>
                  <div className="text-danger mt-2"></div>
                </div>
              </div>
              <div className="col-6 px-2">
                <MultiSelectField
                  label={t("Interviewer")}
                  labelClass={`${
                    formik.values[index]?.interviewType === "SCREENING"
                      ? ""
                      : "required"
                  }`}
                  field={{
                    name: `${index}.selectedInterviewer`,
                    value: formik.values[index]?.selectedInterviewer || [],
                  }}
                  form={formik}
                  options={interviewerList}
                  error={Boolean(
                    (formik.errors[index] as FormikErrors<any>)
                      ?.selectedInterviewer &&
                      formik.touched[index]?.selectedInterviewer
                  )}
                  helperText={
                    formik.touched[index]?.selectedInterviewer &&
                    (formik.errors[index] as FormikErrors<any>)
                      ?.selectedInterviewer
                  }
                />
              </div>
              {formik.values[index]?.interviewType === "SCREENING" && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <SelectInput
                        label={t("Language")}
                        name={`${index}.selectedAgentLanguage`}
                        placeholder="City"
                        labelClass="required"
                        selectedOption={
                          formik.values[index]?.selectedAgentLanguage
                        }
                        setSelectedOption={onChangeSelectedField}
                        options={aiAgentLanguage}
                        form={formik}
                      />
                    </div>{" "}
                  </div>
                  <div>
                    {formik.values[index]?.selectedAgentLanguage?.value.length >
                      0 && <div className="required mb-1">Character</div>}
                    <div className="d-flex gap-5">
                      {aiAgent.map((item: any) => {
                        if (
                          formik.values[index]?.selectedAgentLanguage?.value ===
                          item.agentLanguage
                        ) {
                          return (
                            <EventDivBoxLayout
                              key={item.guid}
                              onClickEvent={() => {
                                stopAudio();
                                setAudioPath(toAbsoluteUrl(item.audioPath));
                                formik.setFieldValue(
                                  `${index}.aiAgentId`,
                                  item.guid
                                );
                              }}
                            >
                              <div>
                                <img
                                  alt="avtar"
                                  className={`w-70px rounded rad ${
                                    formik.values[index]?.aiAgentId ===
                                    item.guid
                                      ? "border border-3 border-primary"
                                      : ""
                                  }`}
                                  src={toAbsoluteUrl(item.imagePath)}
                                />
                              </div>
                              <div
                                className={` ${
                                  formik.values[index]?.aiAgentId === item.guid
                                    ? "text-dark"
                                    : "text-muted"
                                }`}
                              >
                                {item.displayName}
                              </div>
                            </EventDivBoxLayout>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                    {audioPath.length > 0 && (
                      <button
                        className="btn btn-primary fw-bold mt-2"
                        onClick={() => {
                          playAudio(audioPath);
                        }}
                      >
                        Test voice
                        {isPlaying ? (
                          <FaPauseCircle className="ms-4 fs-1" />
                        ) : (
                          <FaCirclePlay className="ms-4 fs-1" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="d-flex flex-stack pt-10">
            <div className="mr-2">
              <button
                onClick={backStep}
                type="button"
                className="btn btn-lg btn-light-primary me-3"
                data-kt-stepper-action="previous"
              >
                <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                Back
              </button>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-lg btn-primary me-3"
                disabled={formik.isSubmitting}
                onClick={formik.submitForm}
              >
                Submit
                <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default ScheduleInterviewForm;
