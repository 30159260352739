import Select from "react-select";
import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  RefObject,
  Dispatch,
  SetStateAction,
} from "react";

type Option = {
  target: {
    value: string;
    label: string;
    name?: string;
  };
};

interface InputTextFieldProps {
  placeholder?: string;
  id?: string;
  name: string;
  isAutoFocus?: boolean;
  value?: string;
  size?: "sm" | "md" | "lg";
  label?: string;
  isDisable?: boolean;
  error?: boolean;
  helperText?: any;
  isvalid?: any;
  variant?: string;
  labelClass?: string;
  ref?: RefObject<HTMLInputElement>;
  forwardedRef?: RefObject<HTMLInputElement>;
  options: any[];
  selectedOption?: {
    value: string;
    label: string;
    name?: string;
  } | null;
  setSelectedOption?: Dispatch<SetStateAction<Option | null>>;
  isHideBorder?: boolean;
  onChange?: (event: ChangeEvent<any>) => void;
  onBlur?: (event: FocusEvent<any>) => void;
  onKeyDown?: (event: KeyboardEvent<any>) => void;
  form?: any;
}

const SelectInput: FC<InputTextFieldProps> = (
  {
    id,
    label,
    size = "md",
    isAutoFocus = false,
    error,
    isDisable = false,
    helperText,
    variant = "outline",
    labelClass = " ",
    options,
    name,
    selectedOption,
    isHideBorder = false,
    placeholder = "",
    form,
    setSelectedOption,
    ...rest
  },
  ref
) => {
  const onChange = (target: any) => {
    if (form) {
      form.setFieldValue(target.target.name, {
        value: target.target.value,
        label: target.target.label,
      });
    } else if (setSelectedOption) {
      setSelectedOption(target);
    }
  };

  return (
    <div className={`fv-row ${!isHideBorder && "mb-5"}`}>
      {label && (
        <label
          htmlFor={id}
          className={"form-label text-dark fs-6 m-2  " + labelClass}
        >
          {label}
        </label>
      )}
      <Select
        defaultValue={selectedOption || form?.values[name] || ""}
        value={selectedOption || form?.values[name] || ""}
        onChange={(e: any) =>
          onChange({
            target: {
              name: name,
              label: e.label,
              value: e.value,
            },
          })
        }
        options={options}
        placeholder={placeholder}
        className="react-select-styled react-select-solid"
        classNamePrefix="react-select"
      />

      {helperText && helperText.length > 0 && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{helperText}</span>
          </div>
        </div>
      )}

      {name && form?.touched?.[name] && Boolean(form?.errors?.[name]) && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">
              {form?.errors?.[name]?.value ?? form?.errors?.[name]}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
