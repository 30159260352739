import React, { useEffect, useRef, useCallback } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../../assets/ts/_utils';

type Props = {
  className?: string;
  skillsRating: string; 
};

const TechnicalEvaluationChart: React.FC<Props> = ({ className, skillsRating }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const refreshChart = useCallback(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(skillsRating));
    if (chart) {
      chart.render();
    }

    return chart;
  }, [skillsRating]);

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [refreshChart]);

  return (
    <div className={`card p-5 ${className}`} style={{ padding: '10px', margin: '0' }}>
      <h3 className="card-title fw-bold fs-4 mb-3" style={{ margin: '0' }}>
        Technical Evaluations
      </h3>
      <div ref={chartRef} id="technical_evaluation_chart" style={{ height: '350px' }}></div>
    </div>
  );
};

export { TechnicalEvaluationChart };

function getChartOptions(skillsRating: string): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-700');
  const borderColor = getCSSVariableValue('--bs-gray-200');

  const ratings = JSON.parse(skillsRating); // Convert JSON string to object
  const categories = Object.keys(ratings); // Skill names
  const data = Object.values(ratings); // Skill ratings

  const colors = ['#3E97FF', '#F1416C', '#50CD89', '#F6C000',"#3E97FF"];

  return {
    series: [
      {
        name: 'Rating',
        data: data as number[], 
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true, 
        borderRadius: 4,
        barHeight: '30%', 
        distributed: true, 
      },
    },
    xaxis: {
        categories: categories, 
        labels: {
          style: {
            colors: labelColor,
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: true, 
          color: borderColor, 
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false, 
        },
      },
      
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '16px',
        },
      },
    },
    dataLabels: {
      enabled: false, 
    },
    colors: colors, 
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0, 
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val}/5`, 
      },
    },
    legend: {
      show: false,
    },
  };
}
