import clsx from "clsx";
import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  RefObject,
} from "react";

interface InputTextFieldProps {
  type?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  label?: string;
  isDisable?: boolean;
  isAutoFocus?: boolean;
  value?: string;
  size?: "sm" | "md" | "lg";
  onChange?: (event: ChangeEvent<any>) => void;
  onBlur?: (event: FocusEvent<any>) => void;
  onKeyDown?: (event: KeyboardEvent<any>) => void;
  onFocus?: (event: FocusEvent<any>) => void;
  error?: boolean;
  helperText?: any;
  isvalid?: any;
  variant?: string;
  labelClass?: string;
  ref?: RefObject<HTMLInputElement>;
  forwardedRef?: RefObject<HTMLInputElement>;
}

const InputTextField: FC<InputTextFieldProps> = (
  {
    type = "text",
    id,
    label,
    size = "md",
    isAutoFocus = false,
    error,
    isDisable = false,
    helperText,
    variant = "outline",
    labelClass = " ",
    ...rest
  }, 
  ref
) => {
  let borderClass = "";

  if (isDisable) {
    borderClass = "border-0";
  } else if (error || variant === "outline") {
    borderClass = "border-1";
  } else {
    borderClass = "border-0";
  }

  const inputClass = `  form-control  form-control-lg   form-control-solid py-2 px-3 form-control-${size}  ${borderClass} ${
    variant === "contained" ? "radius-12 bg-field" : " rounded-5"
  }`;

  return (
    <div className="fv-row mb-5">
      {label && (
        <label htmlFor={id} className={"form-label fs-6 m-2  " + labelClass}>
          {label}
        </label>
      )}

      <input
        type={type}
        autoFocus={isAutoFocus}
        autoComplete="off"
        className={clsx(` ${inputClass} `, {
          "is-invalid ": error,
        })}
 
        disabled={isDisable}
        {...rest}
      />
      {error && (
        <div className="fv-plugins-message-container ">
          <div className="fv-help-block">
            <span role="alert">{helperText}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputTextField;
