import React, { FC } from "react";
interface Prop {
  score: number | string;
}
const SingleStartRating: FC<Prop> = ({ score }) => {
  if (score === 0) {
    return <i className="bi bi-star text-warning fs-3"></i>;
  } else if (score === 5) {
    return <i className="bi bi-star-fill text-warning fs-3"></i>;
  } else {
    return <i className="fa fa-star-half-alt me-1 text-warning fs-3"></i>;
  }
};

export default SingleStartRating;
