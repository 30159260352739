import React, { FC, useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import { MdRemoveRedEye } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { ErrorMessage, Form, Formik } from "formik";
import { string, object, array } from "yup";
import { MenuComponent, StepperComponent } from "assets/ts/components";
import SelectInput from "components/form/SelectInput";
import clsx from "clsx";
import { useJobContext } from "hooks‬/JobContext";
import { useCommonContext } from "hooks‬/CommonContext";
import MultiSelectField from "components/form/MultiSelectField";
import { createJob } from "services/jobAPIs";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { useNavigate } from "react-router";
import { privateRoutes } from "constant/route/privateRoutes";
import { KTIcon } from "helpers";
import InputField from "components/form/formik-field/InputField";
import StepperIcon from "../../components/stepper/StepperIcon";
import StepperBox from "components/stepper/StepperBox";
import StepperStepFormTitle from "components/stepper/StepperStepFormTitle";
import axios from "axios";
import { BsStars } from "react-icons/bs";

import { marked } from "marked";
import DOMPurify from "dompurify";
import FormTextEditor from "components/text-editor/FormTextEditor";
const textareaClass = `form-control py-2 px-3 form-control-md  b rounded-5 form-control-solid `;
const inputClass = `form-control py-2 px-3 form-control-md  bg-transparent rounded-5`;
const yesNoOpens = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
const initSelectValue = {
  label: "",
  value: "",
};
const { REACT_APP_CLOUD_FUNCTIONS_URL_JD } = process.env;
const CreateJobStepperForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [jobRequirements, setJobRequirements] = useState<string>("");
  const [jobDescription, setJobDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [promptError, setPromptError] = useState<string>("");
  const [jobDescriptionError, setJobDescriptionError] = useState<string>("");
  const [isEditableJD, setIsEditableJD] = useState(false);

  const {
    jobShift,
    companyBranches,
    jobCategories,
    jobTypes,
    jobWorkModeLocation,
    jobDescriptionSections,
    fetchAllCompanyBranches,
    fetchJobShiftsHandler,
    fetchJobTypesHandler,
    fetchJobCategoriesHandler,
    fetchJobWorkModeLocationHandler,
    fetchAllJobDescriptionSectionsHandler,
  } = useJobContext();
  const {
    currencies,
    fetchCurrenciesHandler,
    showSuccessToast,
    showErrorToast,
  } = useCommonContext();
  const validationRules = useAuthValidationRules();
  const divRef = useRef<any>(null);
  const getHtmlString = () => {
    if (divRef.current) {
      const htmlString = divRef.current.innerHTML;
      return htmlString;
    }
    return "";
  };

  const initialValues: any = {
    jobDisplayName: "",
    companyBranch: [],
    jobShift: [],
    jobType: [],
    workLocation: [],
    selectedJobCategory: initSelectValue,
    selectedCurrency: initSelectValue,
    ctc: "",
    isNegotiable: initSelectValue,
    minimumPackage: "",
    maximumPackage: "",
    campaignStartDate: "",
    campaignEndDate: "",
    noticePeriod: "",
    totalVacancy: "",
    jobDescriptionType: "aIGenerated",
  };

  useEffect(() => {
    const fetchSequentially = async () => {
      await Promise.all([fetchAllCompanyBranches(), fetchJobShiftsHandler()]);

      await Promise.all([fetchJobTypesHandler(), fetchJobCategoriesHandler()]);

      await Promise.all([
        fetchJobWorkModeLocationHandler(),
        fetchAllJobDescriptionSectionsHandler(),
        fetchCurrenciesHandler(),
      ]);
    };
    fetchSequentially();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    jobDescriptionSections.forEach((field: any) => {
      initialValues[field.guid] = "";
    });
    // eslint-disable-next-line
  }, [jobDescriptionSections]);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  // ===================================================

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const validateSelectSchema = array()
    .min(1, t("messages:MULTI.SELECT.ERROR"))
    .required("Required");

  const createJobSchemas = [
    object({
      jobDisplayName: validationRules.jobNameValidationSchema,
      companyBranch: validateSelectSchema,
      jobShift: validateSelectSchema,
      jobType: validateSelectSchema,
      workLocation: validateSelectSchema,
      selectedJobCategory: validationRules.jobCategoryValidationSchema,
    }),
    object({
      noticePeriod: validationRules.noticePeriodValidationSchema,
      totalVacancy: validationRules.totalVacancyValidationSchema,
      campaignStartDate: validationRules.campaignStartDateValidationSchema,
      campaignEndDate: validationRules.campaignEndDateValidationSchema,
    }),
    object(
      jobDescriptionSections.reduce((acc: any, field: any) => {
        // Directly using guid as field name
        acc[field.guid] =
          field.isRequired === "Y"
            ? string().required(
                `${field.displayName} ${t("messages:IS.REQUIRED")}`
              )
            : string(); // Optional field
        return acc;
      }, {})
    ),
  ];

  const [currentSchema, setCurrentSchema] = useState<any>(createJobSchemas[0]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current.goPrev();

    setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = async (values: any, actions: any) => {
    if (!stepper.current) {
      return;
    }

    if (
      stepper.current.currentStepIndex === 3 &&
      values.jobDescriptionType !== "manual" &&
      jobDescription.length <= 0
    ) {
      setJobDescriptionError("Job Description is required");
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext();
    } else {
      const jobDescriptionSectionsToSend = jobDescriptionSections
        .map((items: any, index) => {
          if (values[items.guid] && values[items.guid].length > 0) {
            return {
              jobDescriptionSectionGuid: items.guid,
              jobDescriptionContent: values[items.guid],
              priorityOrder: index,
            };
          } else {
            return null;
          }
        })
        .filter((section) => section !== null);
      const campaignStartDateToSend = values.campaignStartDate.replace(
        "T",
        " "
      );
      const campaignEndDateToSend = values.campaignEndDate.replace("T", " ");

      const jsonToSend = {
        jobDisplayName: values.jobDisplayName,
        jobCategoryGuid: values.selectedJobCategory.value,
        jobShift: values.jobShift,
        jobType: values.jobType,
        workLocation: values.workLocation,
        companyBranch: values.companyBranch,
        ctc: values.ctc,
        isNegotiable: values.isNegotiable.value,
        minimumPackage: values.minimumPackage,
        maximumPackage: values.maximumPackage,
        campaignStartDate: campaignStartDateToSend,
        campaignEndDate: campaignEndDateToSend,
        noticePeriod: values.noticePeriod,
        totalVacancy: values.totalVacancy,
        currencyGuid: values.selectedCurrency.value,
        jobDescription:
          values.jobDescriptionType === "manual"
            ? getHtmlString()
            : jobDescription,
        jobDescriptionSections:
          values.jobDescriptionType === "manual"
            ? jobDescriptionSectionsToSend
            : [],
      };

      actions.setSubmitting(true);
      await createJob(jsonToSend)
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            showErrorToast(response.data.statusDesc);
          } else {
            showSuccessToast(t("messages:CREATED.SUCCESSFULLY"));
            actions.setSubmitting(false);
            navigate(privateRoutes.jobs);
          }
        })
        .catch((error: any) => {
          actions.setSubmitting(false);
        });
    }

    if (stepper.current.currentStepIndex !== 3) {
      setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex - 1]);
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);
  // =====================================

  const handleGenerate = async (formData?: any): Promise<void> => {
    console.log(formData);
    if (!jobRequirements) {
      setPromptError("Please enter job requirements!");
      return;
    }

    setPromptError("");
    setLoading(true);

    try {
      const response = await axios.post<any>(
        REACT_APP_CLOUD_FUNCTIONS_URL_JD || "",
        {
          job_requirements: jobRequirements,
          job_details: formData,
        }
      );

      const markdownText = response.data;

      try {
        const htmlContent = await marked(markdownText);
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        setJobDescription(sanitizedHtml);
      } catch (error) {
        console.error("Error converting markdown:", error);
      }
    } catch (err) {
      setPromptError("An error occurred while generating the job description.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
      id="kt_create_account_stepper"
    >
      <StepperBox>
        <StepperIcon
          step="1"
          title="Job Details"
          description="Fill Your Job Details"
          isCurrent={true}
        />
        <StepperIcon
          step="2"
          title="Position Details"
          description="Fill Your Position Details"
        />
        <StepperIcon
          step="3"
          title="Job Description"
          description="Fill Your Job Description Details"
          isLast={true}
        />
      </StepperBox>

      <div className="d-flex flex-row-fluid flex-center bg-body rounded">
        <Formik
          validationSchema={currentSchema}
          initialValues={initialValues}
          onSubmit={submitStep}
        >
          {(formik) => {
            return (
              <Form
                placeholder={"he"}
                className=" w-100  p-10 p-lg-15 p-xxl-15"
                noValidate
                id="kt_create_account_form"
              >
                {/* STEP:01 */}
                <DynamicCurrencySetter
                  currencies={currencies}
                  formik={formik}
                />

                <div className="current" data-kt-stepper-element="content">
                  <div className="w-100">
                    <StepperStepFormTitle title="Fill Job Details" />
                    <div className="row">
                      <div className="col-6">
                        <InputField
                          name="jobDisplayName"
                          label={t("JOB.NAME")}
                        />
                      </div>
                      <div className="col-6">
                        <MultiSelectField
                          label={t("BRANCH.NAME")}
                          labelClass="required"
                          field={{
                            name: "companyBranch",
                            value: formik.values.companyBranch,
                          }}
                          form={formik}
                          options={companyBranches}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <MultiSelectField
                          label={t("JOB.SHIFT")}
                          labelClass="required"
                          field={{
                            name: "jobShift",
                            value: formik.values.jobShift,
                          }}
                          form={formik}
                          options={jobShift}
                        />
                      </div>
                      <div className="col-6">
                        <MultiSelectField
                          label={t("JOB.TYPE")}
                          labelClass="required"
                          field={{
                            name: "jobType",
                            value: formik.values.jobType,
                          }}
                          form={formik}
                          options={jobTypes}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <MultiSelectField
                          label={t("WORK.MODE")}
                          labelClass="required"
                          field={{
                            name: "workLocation",
                            value: formik.values.workLocation,
                          }}
                          form={formik}
                          options={jobWorkModeLocation}
                        />
                      </div>
                      <div className="col-6">
                        <SelectInput
                          label={t("JOB.CATEGORY")}
                          labelClass="required"
                          name="selectedJobCategory"
                          form={formik}
                          options={jobCategories}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* STEP:02 */}
                <div data-kt-stepper-element="content">
                  <div className="w-100">
                    <StepperStepFormTitle title="Fill Position Details" />
                    <div className="row">
                      <div className="col-6">
                        <div className="fv-row mb-5 form-group">
                          <label
                            className={
                              "form-label text-dark fs-6 m-2 text-muted "
                            }
                          >
                            {t("CTC.LPA")}
                          </label>

                          <div className="input-group">
                            <input
                              type="number"
                              autoComplete="off"
                              name="ctc"
                              value={formik.values.ctc}
                              onChange={formik.handleChange}
                              className={clsx(` ${inputClass} `, {
                                "is-invalid ": false,
                              })}
                            />
                            <div className="input-group-append">
                              <span
                                style={{
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                }}
                                className="input-group-text  p-0"
                              >
                                <SelectInput
                                  form={formik}
                                  selectedOption={
                                    formik.values.selectedCurrency
                                  }
                                  options={currencies}
                                  name="selectedCurrency"
                                  isHideBorder={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <SelectInput
                          label={t("IS.NEGOTIABLE")}
                          name="isNegotiable"
                          form={formik}
                          selectedOption={formik.values.isNegotiable}
                          options={yesNoOpens}
                          helperText={
                            formik.touched.isNegotiable &&
                            formik.errors.isNegotiable
                          }
                          error={
                            formik.touched.isNegotiable &&
                            Boolean(formik.errors.isNegotiable)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="fv-row mb-5 form-group">
                          <label
                            className={
                              "form-label text-dark fs-6 m-2 text-muted "
                            }
                          >
                            {t("MINIMUM.PACKAGE")}
                          </label>

                          <div className="input-group">
                            <input
                              type="number"
                              autoComplete="off"
                              name="minimumPackage"
                              value={formik.values.minimumPackage}
                              onChange={formik.handleChange}
                              className={clsx(` ${inputClass} `, {
                                "is-invalid ": false,
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text p-0 radius-left-0">
                                <SelectInput
                                  form={formik}
                                  selectedOption={
                                    formik.values.selectedCurrency
                                  }
                                  options={currencies}
                                  name="selectedCurrency"
                                  error={false}
                                  isHideBorder={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fv-row mb-5 form-group">
                          <label
                            className={
                              "form-label text-dark fs-6 m-2 text-muted "
                            }
                          >
                            {t("MAXIMUM.PACKAGE")}
                          </label>

                          <div className="input-group ">
                            <input
                              type="number"
                              autoComplete="off"
                              name="maximumPackage"
                              value={formik.values.maximumPackage}
                              onChange={formik.handleChange}
                              className={clsx(` ${inputClass} `, {
                                "is-invalid ": false,
                              })}
                            />
                            <div className="input-group-append">
                              <span
                                style={{
                                  borderTopLeftRadius: "0px",
                                  borderBottomLeftRadius: "0px",
                                }}
                                className="input-group-text p-0"
                              >
                                <SelectInput
                                  selectedOption={
                                    formik.values.selectedCurrency
                                  }
                                  form={formik}
                                  options={currencies}
                                  name="selectedCurrency"
                                  error={false}
                                  isHideBorder={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <InputField
                          name="campaignStartDate"
                          label={t("CAMPAIGN.START.DATE")}
                          type="datetime-local"
                        />
                      </div>
                      <div className="col-6">
                        <InputField
                          name="campaignEndDate"
                          label={t("CAMPAIGN.END.DATE")}
                          type="datetime-local"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <InputField
                          name="noticePeriod"
                          label={t("NOTICE.PERIOD")}
                          type="number"
                        />
                      </div>
                      <div className="col-6">
                        <InputField
                          name="totalVacancy"
                          label={t("TOTAL.VACANCY")}
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* STEP:03 */}
                <div data-kt-stepper-element="content">
                  <div className="w-100">
                    <StepperStepFormTitle title="Fill Job Details" />

                    <div className="row">
                      <div className="col-6 px-2">
                        <div className="fv-row mb-5">
                          <div className="fs-6 form-label my-2 required">
                            Interview type
                          </div>
                          <div className="d-flex gap-4">
                            <span className="d-flex gap-1">
                              <div className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="jobDescriptionType"
                                  value="aIGenerated"
                                  checked={
                                    formik.values.jobDescriptionType ===
                                    "aIGenerated"
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setCurrentSchema(createJobSchemas[1]);
                                  }}
                                />
                              </div>
                              AI Generated
                            </span>
                            <span className="d-flex gap-1">
                              <div className="form-check form-check-custom form-check-solid">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="jobDescriptionType"
                                  value="manual"
                                  checked={
                                    formik.values.jobDescriptionType ===
                                    "manual"
                                  }
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    setCurrentSchema(createJobSchemas[2]);
                                  }}
                                />
                              </div>
                              Manual
                            </span>
                          </div>
                          <div className="text-danger mt-2"></div>
                        </div>
                      </div>
                    </div>
                    {formik.values.jobDescriptionType === "manual" ? (
                      <div className="row">
                        {jobDescriptionSections.map((field) => {
                          return (
                            <div className="col-6" key={field.guid}>
                              <div className="fv-row mb-5 form-group">
                                <label
                                  className={`form-label text-dark fs-6 m-2 text-muted  ${
                                    field.isRequired === "Y" && "required"
                                  }`}
                                >
                                  {field.displayName}
                                </label>
                                <div>
                                  <textarea
                                    autoComplete="off"
                                    name={field.guid}
                                    className={clsx(` ${textareaClass} `, {
                                      "is-invalid ":
                                        formik.touched[field.guid] &&
                                        Boolean(formik.errors[field.guid]),
                                    })}
                                    value={formik.values[field.guid]}
                                    onChange={formik.handleChange}
                                    style={{ height: "100px", resize: "none" }}
                                  />
                                </div>

                                <div className="text-danger mt-2">
                                  <ErrorMessage name={field.guid} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* Preview */}
                      </div>
                    ) : (
                      <div className="row">
                        <span className="fw-bold required mx-0 p-0 mb-2">
                          {" "}
                          Enter prompt{" "}
                        </span>
                        <textarea
                          className="form-control  form-control-solid"
                          placeholder="Enter job requirements here..."
                          value={jobRequirements}
                          onChange={(e) => setJobRequirements(e.target.value)}
                        ></textarea>
                        <div className="text-danger mb-2">{promptError}</div>
                        <div className="my-4 mx-0 p-0">
                          {" "}
                          <button
                            className={`btn btn-primary mx-0  ${
                              loading && "spinner spinner-white spinner-right"
                            }`}
                            onClick={() => handleGenerate(formik.values)}
                            disabled={loading}
                            type="button"
                          >
                            {loading ? (
                              <>
                                Generating{" "}
                                <span className="spinner-border spinner-border-sm ms-2"></span>
                              </>
                            ) : (
                              <>
                                {" "}
                                Generate <BsStars className="ms-2" />
                              </>
                            )}
                          </button>
                        </div>
                        {jobDescription.length > 0 && isEditableJD && (
                          <div className="mt-0 p-0">
                            <div className="d-flex justify-content-end my-3">
                              <button
                                onClick={() => setIsEditableJD(false)}
                                className="btn fw-bold btn-sm btn-light-primary"
                              >
                                Save
                              </button>
                            </div>
                            <FormTextEditor
                              value={jobDescription}
                              setValue={setJobDescription}
                            />
                          </div>
                        )}
                        {jobDescription.length > 0 && !isEditableJD && (
                          <div className="border p-10 rounded mt-5">
                            <div className="d-flex justify-content-end">
                              <button
                                onClick={() => setIsEditableJD(true)}
                                className="btn btn-sm btn-light-primary  fw-bold"
                              >
                                <KTIcon iconName="pencil" /> Edit
                              </button>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobDescription,
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    )}
                    {jobDescriptionError.length > 0 && (
                      <div className="text-danger">{jobDescriptionError}</div>
                    )}
                  </div>
                </div>
                <div className="modal fade " tabIndex={-1} id="preview_modal">
                  <div className=" modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                      <div className="modal-header justify-content-end border-0 pb-0">
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <IoClose className="fs-1" />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div ref={divRef}>
                          {jobDescriptionSections.map((field) => {
                            if (formik.values[field.guid]?.length > 0) {
                              return (
                                <React.Fragment
                                  key={"prev" + formik.values[field.guid]}
                                >
                                  <h6>{field.displayName}</h6>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: formik.values[
                                        field.guid
                                      ]?.replace(/\n/g, "<br />"),
                                    }}
                                  ></p>
                                </React.Fragment>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-stack pt-10">
                  <div className="mr-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3 fw-bold"
                      data-kt-stepper-action="previous"
                    >
                      <KTIcon
                        iconName="arrow-left"
                        className="fs-4 me-1 fw-bold"
                      />
                      Back
                    </button>
                  </div>

                  <div className="d-flex gap-3">
                    {stepper.current?.currentStepIndex ===
                      stepper.current?.totalStepsNumber &&
                      formik.values.jobDescriptionType === "manual" && (
                        <button
                          type="button"
                          className="btn btn-light-primary fw-bold"
                          data-bs-toggle="modal"
                          data-bs-target="#preview_modal"
                        >
                          Preview <MdRemoveRedEye />
                        </button>
                      )}
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3 fw-bold"
                      disabled={formik.isSubmitting}
                    >
                      {stepper.current?.currentStepIndex ===
                      stepper.current?.totalStepsNumber
                        ? "Submit"
                        : "Continue"}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-2 me-0"
                      />
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateJobStepperForm;

const DynamicCurrencySetter: FC<any> = ({ currencies, formik }) => {
  useEffect(() => {
    const indianCurrency = currencies.find((item: any) => item.label === "INR");
    formik.setFieldValue("selectedCurrency", {
      label: indianCurrency?.label,
      value: indianCurrency?.value,
    });
    // eslint-disable-next-line
  }, [currencies]);
  return null; // This component doesn't render anything
};
