import { Collapse } from "react-bootstrap";
import { WithChildren } from "helpers";
import React, { FC } from "react";
import { IoIosArrowUp } from "react-icons/io";
import EventDivBoxLayout from "layout/EventDivBoxLayout";
import SingleStartRating from "components/ui-components/SingleStartRating";

interface Props {
  title: string;
  score: string | number;
  setCollapseSection: () => void;
  open: boolean;
}
const ChatHistoryCollapse: FC<Props & WithChildren> = ({
  title,
  children,
  open,
  score,
  setCollapseSection,
}) => {
  return (
    <div className="n-card-bg radius-12 ">
      <EventDivBoxLayout
        onClickEvent={() => {
          setCollapseSection();
        }}
        className="  d-flex justify-content-between w-100  p-5 radius-12"
      >
        <div className=" fw-semibold fs-16">{title}</div>
        <div className="d-flex justify-content-end align-items-center gap-5 w-175px">
          <div className="d-flex gap-1 justify-content-between w-50px align-items-center">
            <SingleStartRating score={score} />
            <span className="text-gray-800  fs-14"> {score}/5</span>
          </div>
          <IoIosArrowUp className={`arrow ${open ? "rotate" : ""}`} />
        </div>
      </EventDivBoxLayout>
      <Collapse in={open}>
        <div className="p-0">
          <div className="p-4">{children}</div>
        </div>
      </Collapse>
    </div>
  );
};

export default ChatHistoryCollapse;
