import Select, { MultiValue, ActionMeta } from "react-select";
interface OptionType {
  guid: string;
  displayName: string;
}

const MultiSelectField = ({
  field,
  form,
  options,
  label = "",
  isHideBorder = false,
  id = "",
  labelClass = "",
  error = null,
  helperText = null,
}: {
  field: { name: string; value: MultiValue<OptionType> };
  form: any;
  options: OptionType[];
  label?: string;
  isHideBorder?: boolean;
  id?: string;
  labelClass?: string;
  error?: any;
  helperText?: any;
}) => {
  const onChange = (
    selectedOptions: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    form.setFieldValue(field.name, selectedOptions);
  };

  return (
    <div className={`fv-row ${!isHideBorder && "mb-5"}`}>
      {label && (
        <label
          htmlFor={id}
          className={"form-label text-dark fs-6 m-2  " + labelClass}
        >
          {label}
        </label>
      )}
      <Select
        isMulti
        name={field.name}
        value={field.value}
        options={options}
        onChange={onChange}
        getOptionLabel={(option: OptionType) => option.displayName}
        getOptionValue={(option: OptionType) => option.guid}
        placeholder=""
        isClearable={false}
        className="react-select-styled react-select-solid"
        classNamePrefix="react-select"
      />

      {error && <div className="text-danger">{helperText}</div>}

      {form.errors[field.name] && form.touched[field.name] && (
        <div className="text-danger">
          {Array.isArray(form.errors[field.name])
            ? form.errors[field.name].join(", ")
            : form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default MultiSelectField;
