import React from "react";

interface StepperIconProps {
  step: number | string; // Assuming step can be a number or string
  title: string;
  description: string;
  isCurrent?: boolean;
  isLast?: boolean;
}

const StepperIcon: React.FC<StepperIconProps> = ({
  step,
  title,
  description,
  isCurrent,
  isLast,
}) => (
  <div
    className={`stepper-item ${isCurrent ? "current" : ""}`}
    data-kt-stepper-element="nav"
  >
    <div className="stepper-wrapper">
      <div className="stepper-icon w-40px h-40px">
        <i className="stepper-check fas fa-check"></i>
        <span className="stepper-number">{step}</span>
      </div>
      <div className="stepper-label">
        <h3 className="stepper-title">{title}</h3>
        <div className="stepper-desc fw-semibold">{description}</div>
      </div>
    </div>
    {!isLast && <div className="stepper-line h-40px"></div>}
  </div>
);

export default StepperIcon;
