export const privateRoutes = {
  dashboard: "/dashboard",
  jobs: "/jobs",
  client: "/client",
  candidates: "/candidates",
  createCandidates: "/candidates/create",
  placement: "/placement",
  activities: "/activities",
  inbox: "inbox",
  reports: "/reports",
  settings: "/settings",
  administration: "Administration",
  interviews: "/interviews",
  interviewsUpcoming: "/interviews/upcoming",
  interviewsCompleted: "/interviews/completed",
};

export const privateRoutesArray: string[] = (
  Object.keys(privateRoutes) as Array<keyof typeof privateRoutes>
).map((key) => privateRoutes[key]);
