import React, { FC, ReactNode } from "react";
interface Props {
  title: string;
  children: ReactNode;
}
const PageContainer: FC<Props> = ({ title, children }) => {
  return (
    <div>
      <h1 className="mb-5">{title}</h1>
      {children}
    </div>
  );
};

export default PageContainer;
