import React from "react";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

import { useTranslation } from "react-i18next";
import {
  SidebarMenuItemType,
  sidebarMenuItems,
} from "constant/dashboardConstant";
import { interviewerSidebarMenuItems } from "constant/dashboardConstant";
import { useEffect, useState } from "react";
import { useAuth } from "hooks‬/Auth";
import { userRoleKey } from "constant/commonConstant";

const SidebarMenuMain = () => {
  // const intl = useIntl();

  const { t } = useTranslation();
  const { loggedUserRole } = useAuth();
  const [menuItem, setMenuItem] = useState<SidebarMenuItemType[]>([]);

  // const isAdmin = loggedUserRole.user_role === userRoleKey.admin;
  // const isHR = loggedUserRole.user_role === userRoleKey.hr;
  const isInterviewer = loggedUserRole.user_role === userRoleKey.interviewer;

  useEffect(() => {
    if (isInterviewer) {
      setMenuItem(interviewerSidebarMenuItems);
    } else {
      setMenuItem(sidebarMenuItems);
    }
    // eslint-disable-next-line
  }, [loggedUserRole]);

  return (
    <>
      {menuItem.length > 0 &&
        menuItem.map((item, index) => {
          if (item.subItems) {
            return (
              <SidebarMenuItemWithSub
                key={item.to}
                to={item.to}
                title={t(item.titleKey)}
                icon={item.icon}
                fontIcon={item.fontIcon}
              >
                {item.subItems.map((subItem, subIndex) => (
                  <SidebarMenuItem
                    key={subIndex}
                    to={subItem.to}
                    title={t(subItem.titleKey)}
                    hasBullet={subItem.hasBullet}
                  />
                ))}
              </SidebarMenuItemWithSub>
            );
          }

          return (
            <SidebarMenuItem
              key={index}
              to={item.to}
              icon={item.icon}
              title={t(item.titleKey)}
              fontIcon={item.fontIcon}
            />
          );
        })}
    </>
  );
};

export { SidebarMenuMain };
