import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import SelectInput from "components/form/SelectInput";
import MultiSelectField from "components/form/MultiSelectField";
import ScheduleInterviewForm from "./ScheduleInterviewForm";
import { getAllJobs, getAllUnscheduledCandidate } from "services/jobAPIs";
import StepperBox from "components/stepper/StepperBox";
import StepperIcon from "components/stepper/StepperIcon";
import { MenuComponent, StepperComponent } from "assets/ts/components";
import { KTIcon } from "helpers";
import StepperStepFormTitle from "components/stepper/StepperStepFormTitle";

interface CandidateType {
  candidateApplicationGuid: string;
  jobDetailsGuid: string;
  candidateName: string;
}

const ScheduleInterview = () => {
  const { t } = useTranslation();
  const [jobOption, setJobOption] = useState<any[]>([]);
  const [candidateOption, setCandidateOption] = useState<any[]>([]);
  const [candidatesToSchedule, setCandidatesToSchedule] = useState<
    CandidateType[]
  >([]);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const initialValues: any = {
    selectJob: null,
    selectedCandidates: [],
  };

  const validationSchema = Yup.object({
    selectJob: Yup.object().nullable().required("Select a job option"),

    selectedCandidates: Yup.array()
      .min(1, "At least one candidate must be selected")
      .required("Candidates are required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!stepper.current) {
        return;
      }
      if (
        stepper.current.currentStepIndex !== stepper.current.totalStepsNumber
      ) {
        setCandidatesToSchedule(
          values.selectedCandidates.map((item: any) => ({
            candidateApplicationGuid: item.guid,
            jobDetailsGuid: values.selectJob.value,
            candidateName: item.displayName,
          }))
        );
        stepper.current.goNext();
        setSubmitting(false);
      } else {
        setSubmitting(true);
      }
    },
  });

  const getAllJobsHandler = async () => {
    try {
      await getAllJobs()
        .then((res: any) => {
          setJobOption(
            res.data.data.map((item: any) => ({
              label: item.jobDetails.displayName,
              value: item.jobDetails.jobDetailsGuid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  const candidate_applicationHandler = async (guid: string) => {
    try {
      await getAllUnscheduledCandidate(guid)
        .then((res: any) => {
          setCandidateOption(
            res.data.data.map((item: any) => ({
              displayName:
                item.candidateDetails.candidateDetails.firstName +
                " " +
                item.candidateDetails.candidateDetails.lastName,
              guid: item.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current.goPrev();
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    MenuComponent.reinitialization();
    getAllJobsHandler();
  }, []);

  useEffect(() => {
    if (formik.values.selectJob?.value)
      candidate_applicationHandler(formik.values.selectJob.value);
    // eslint-disable-next-line
  }, [formik.values.selectJob]);

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
      id="kt_create_account_stepper"
    >
      <StepperBox>
        <StepperIcon
          step="1"
          title="Interview Details"
          description="Set up and manage interview sessions"
          isCurrent={true}
        />
        <StepperIcon
          step="2"
          title="Assign interviewers"
          description="Select interviewers and set time slots"
          isLast={true}
        />
      </StepperBox>

      <div className="w-100">
        {/* STEP:01 */}
        <div className="current w-100" data-kt-stepper-element="content">
          <div className="  bg-body rounded   p-10 p-lg-15 p-xxl-15 w-100">
            <div className="w-100">
              <StepperStepFormTitle title="Fill Interview Details" />
              <div className="row justify-content-center">
                <div className="col-8">
                  <SelectInput
                    label={t("Job Name")}
                    labelClass="required"
                    name="selectJob"
                    selectedOption={formik.values.selectJob}
                    setSelectedOption={onChangeSelectedField}
                    options={jobOption}
                    error={
                      formik.touched.selectJob &&
                      Boolean(formik.errors.selectJob)
                    }
                    helperText={
                      formik.touched.selectJob && formik.errors.selectJob
                    }
                    form={formik}
                  />
                  <MultiSelectField
                    label={t("Candidates")}
                    labelClass="required"
                    field={{
                      name: "selectedCandidates",
                      value: formik.values.selectedCandidates,
                    }}
                    form={formik}
                    options={candidateOption}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-stack pt-10">
              <div className="mr-2">
                <button
                  onClick={prevStep}
                  type="button"
                  className="btn btn-lg btn-light-primary me-3"
                  data-kt-stepper-action="previous"
                >
                  <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                  Back
                </button>
              </div>

              <div>
                <button
                  type="submit"
                  className="btn btn-lg btn-primary me-3 "
                  disabled={formik.isSubmitting}
                  onClick={formik.submitForm}
                >
                  Continue
                  <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* STEP:02 */}
        <div data-kt-stepper-element="content">
          <div className=" bg-body rounded   p-10 p-lg-15 p-xxl-15 w-100">
            {stepper.current?.currentStepIndex ===
              stepper.current?.totalStepsNumber && (
              <ScheduleInterviewForm
                candidatesToSchedule={candidatesToSchedule}
                backStep={prevStep}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ScheduleInterview;
