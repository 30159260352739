// import SearchBox from "components/base/SearchBox";

export function MenuInner() {
  return (
    <>
      <div className="menu-item me-lg-1 fs-3  bold text-capitalize">
        {/* <SearchBox/> */}
      </div>
    </>
  );
}
