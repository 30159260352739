import { WithChildren } from "helpers";
import React, { FC } from "react";

const StepperBox: FC<WithChildren> = ({ children }) => {
  return (
    <div>
      <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 mb-6">
        {/* begin::Wrapper*/}
        <div className="card-body p-10 p-lg-15 p-xxl-15">
          {/* begin::Nav*/}
          <div className="stepper-nav">{children}</div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default StepperBox;
