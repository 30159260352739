import apiInterceptor from "./apiInterceptor";

export function getAllInterviewers() {
  return apiInterceptor.get("/ats/v1/interview/interviewers");
}

export function scheduleInterview(json: any[]) {
  return apiInterceptor.post("/ats/v1/interview/schedule", json);
}

export function getAiAgents() {
  return apiInterceptor.get("/ats/v1/interview/ai_agents");
}

