import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { privateRoutes } from "constant/route/privateRoutes";
import NotFound from "pages/error-pages/NotFound";
import PageLoader from "components/Loader/PageLoader";
import { userRoleKey } from "constant/commonConstant";
import { useAuth } from "hooks‬/Auth";
import CreateJobStepperForm from "pages/Jobs/CreateJobStepperForm";
import ScheduleInterview from "pages/interviews/ScheduleInterview";
import Assessment from "pages/interviews/completed/Assessment";
// Lazy-loaded components
const Dashboard = React.lazy(() => import("pages/dashboard"));
const Clients = React.lazy(() => import("pages/clients"));
const ClientProfile = React.lazy(() => import("pages/clients/ClientProfile"));
const Jobs = React.lazy(() => import("pages/Jobs"));
const Candidates = React.lazy(() => import("pages/candidates"));
const CreateCandidateOptions = React.lazy(
  () => import("pages/candidates/components/CreateCandidateOptions")
);
const Interviews = React.lazy(() => import("pages/interviews"));
const UnderConstruction = React.lazy(
  () => import("pages/error-pages/UnderConstruction")
);

const CompletedInterview = React.lazy(
  () => import("pages/interviews/completed")
);
const PrivateRoutes = () => {
  const { loggedUserRole } = useAuth();
  const isAdmin = loggedUserRole.user_role === userRoleKey.admin;
  const isHR = loggedUserRole.user_role === userRoleKey.hr;

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={"/"} element={<Navigate to={privateRoutes.dashboard} />} />
        <Route path={privateRoutes.dashboard} element={<Dashboard />} />

        {(isAdmin || isHR) && (
          <>
            <Route path={privateRoutes.client} element={<Clients />} />
            <Route path={"/client/:clientGuid"} element={<ClientProfile />} />

            {/* <Route
              path={privateRoutes.jobs + "/:guid"}
              element={<JobProfile />}
            /> */}
            <Route path={privateRoutes.jobs} element={<Jobs />} />
            <Route
              path={privateRoutes.jobs + "/create"}
              element={<CreateJobStepperForm />}
            />
            <Route path={privateRoutes.candidates} element={<Candidates />} />
            <Route
              path={privateRoutes.createCandidates}
              element={<CreateCandidateOptions />}
            />
            <Route
              path={privateRoutes.interviewsUpcoming}
              element={<Interviews />}
            />
            <Route
              path={privateRoutes.interviewsCompleted}
              element={<CompletedInterview />}
            />
            <Route
              path={"/interviews/completed/:interviewGuid"}
              element={<Assessment />}
            />
            <Route
              path={privateRoutes.interviews + "/create"}
              element={<ScheduleInterview />}
            />
            <Route path="*" element={<NotFound />} />
          </>
        )}

        <Route path={privateRoutes.placement} element={<UnderConstruction />} />
        <Route
          path={privateRoutes.activities}
          element={<UnderConstruction />}
        />
        <Route path={privateRoutes.inbox} element={<UnderConstruction />} />
        <Route path={privateRoutes.reports} element={<UnderConstruction />} />
        <Route path={privateRoutes.settings} element={<UnderConstruction />} />
        <Route
          path={privateRoutes.administration}
          element={<UnderConstruction />}
        />
      </Routes>
    </Suspense>
  );
};

export { PrivateRoutes };
